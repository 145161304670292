// export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const googlePageView = (url, GA_TRACKING_ID) => {
    window.gtag('config', GA_TRACKING_ID, {
        page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const googleEvent = (action, category, label, value) => {
    console.log(action);
    // window.gtag('event', `'${action}'`, {
    //     'event_category': 'bbb',
    //     'event_label': 'ccc'
    // });
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}
